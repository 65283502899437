import PAGE_SIZE from '@/constants/pageSize';

const getClientPageQueryObject = ({
  searchRequestObject,
  seller,
  categories: linkCategories,
  query: searchQuery,
  page,
  pageSize,
  removeAllFilters,
  removeAllAttributes,
  removeAllOtherFilters = false,
  showQueryInSeoFriendlyPart,
  prioritizedAttributes: seoAttributes,
}) => {
  // Create seller
  const { id: sellerId } = seller || {};

  // Create pagination
  const { pagination: { limit: searchRequestLimit = PAGE_SIZE.MINIMUM, offset: searchRequestOffset = 0 } = {} } =
    searchRequestObject || {};
  const limit = pageSize || searchRequestLimit;
  const offset = page ? limit * (page - 1) : searchRequestOffset;

  const l2Category = removeAllOtherFilters
    ? {}
    : {
        ...(linkCategories.l2Category && { l2CategoryId: linkCategories.l2Category.id }),
      };

  // Create categories
  const categories =
    !removeAllFilters && linkCategories
      ? {
          ...(linkCategories.l1Category && { l1CategoryId: linkCategories.l1Category.id }),
          ...l2Category,
        }
      : {};

  // Create attributes
  const keepAttributes = !removeAllFilters && !removeAllAttributes;

  const attributesValuesIds = keepAttributes
    ? seoAttributes.seoFriendlyAttributes
        .filter((attribute: TAttribute) => attribute.attributeValueId)
        .map((attribute: TAttribute) => attribute.attributeValueId.toString())
    : [];
  const textAttributes = seoAttributes.seoFriendlyTextAttributesRequestObject;

  const textAttributesValuesKeys = Object.keys(textAttributes);
  const textAttributesValuesLabels = textAttributesValuesKeys.map(
    (textAttributeKey) => textAttributes[textAttributeKey].attributeValueLabel,
  );

  return {
    ...(sellerId && { sellerId }),
    ...(showQueryInSeoFriendlyPart && searchQuery && { searchQuery }),
    ...categories,
    attributesValuesIds,
    textAttributesValuesKeys,
    textAttributesValuesLabels,
    limit,
    offset,
  };
};

export default getClientPageQueryObject;
